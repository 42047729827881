import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services-forging-parts',
  templateUrl: './our-services-forging-parts.component.html',
  styleUrls: ['../our-services/our-services.component.css']
})
export class OurServicesForgingPartsComponent implements OnInit {

  services = [
    {
      Group: "Forging Parts",
      Images: [
        {ID: 1, Path: "MSG-Engineers-Forging-Parts-1.jpg"},
        {ID: 2, Path: "MSG-Engineers-Forging-Parts-2.jpg"},
        {ID: 3, Path: "MSG-Engineers-Forging-Parts-3.jpg"},
        {ID: 4, Path: "MSG-Engineers-Forging-Parts-4.jpg"},
        {ID: 5, Path: "MSG-Engineers-Forging-Parts-5.jpg"},
        {ID: 6, Path: "MSG-Engineers-Forging-Parts-6.jpg"},
        {ID: 7, Path: "MSG-Engineers-Forging-Parts-7.jpg"},
        {ID: 8, Path: "MSG-Engineers-Forging-Parts-8.jpg"},
        {ID: 9, Path: "MSG-Engineers-Forging-Parts-9.jpg"}
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
